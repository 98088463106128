<template>
  <shipblu-card :cardLoading="notesCardLoading" class="p-5">
    <div class="flex justify-between items-start">
      <div class="grid sm:grid-cols-3 grid-cols-1 gap-4 w-full relative">
        <div class="col-span-1">
          <div class="flex gap-2">
            <span class="material-symbols-outlined text-primary">barcode</span>
            <div class="w-full">
              <p class="header mb-1 text-lg">{{ $t('Order Reference No.') }}</p>
              <p v-show="!editable || localStorage.getItem('multipleWarehouses') !== 'true'" class="text-darkgray font-medium">{{ orderData.merchant_order_reference ? orderData.merchant_order_reference : '--' }}</p>
              <div v-show="editable && localStorage.getItem('multipleWarehouses') === 'true'">
                <vs-input v-validate="'max:30'" name="merchant order reference" v-model="orderData.merchant_order_reference" class="w-full order-view-input"/>
                <span class="text-danger text-sm" v-show="errors.has('merchant order reference')">{{ errors.first('merchant order reference') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="separator-line notes sm:block hidden"></div>
        <div class="col-span-2">
          <div class="flex gap-2">
            <span class="material-icons-outlined text-primary text-2xl leading-tight">mark_unread_chat_alt</span>
            <div class="w-full">
              <p class="header mb-1 text-lg">{{ $route.params.type === 'delivery-orders'? $t('Delivery Notes') : $t('Pickup Notes') }}</p>
              <p v-show="!editable || localStorage.getItem('multipleWarehouses') !== 'true'" class="text-darkgray font-medium">
                <pre v-if="orderData.order_notes && orderData.order_notes.includes('\n*')" class="whitespace-pre-wrap">{{ orderData.order_notes ? orderData.order_notes : '--' }}</pre>
                <span v-else>{{ orderData.order_notes ? orderData.order_notes : '--' }}</span>
              </p>
              <div v-show="editable && localStorage.getItem('multipleWarehouses') === 'true'" style="width: calc(100% - 28px) !important;">
                <vs-textarea v-validate="$route.params.type === 'returns' ? 'required' : ''" name="delivery notes" rows="4" width="100%" v-model="orderData.order_notes" class="mb-0 w-full" />
                <span class="text-danger text-sm" v-show="errors.has('delivery notes')">{{ errors.first('delivery notes') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="$store.state.AppActiveUser.userRole === 'merchant'">
        <div class="flex" v-if="orderData.status === 'created' || orderData.status === 'return_requested' || orderData.status === 'pickup_requested'">
          <feather-icon icon="EditIcon" svgClasses="h-5 w-5" class="text-primary cursor-pointer" 
            v-if="!editable && merchantStatus === 'active'" @click="editInformation()" />
        </div>
      </div>
      <div class="flex" v-else-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'head-of-fleet' || $store.state.AppActiveUser.userRole === 'customer-support'">
        <feather-icon icon="EditIcon" svgClasses="h-5 w-5" class="text-primary cursor-pointer" 
          v-if="!editable && !(orderData.status === 'delivered' || orderData.status === 'returned' || orderData.status === 'returned_to_origin' || orderData.status === 'cancelled')" @click="editInformation()" />
      </div>
    </div>
    <div v-if="$store.state.AppActiveUser.userRole === 'merchant'">
      <div class="flex gap-4 items-center justify-end" v-if="orderData.status === 'created'|| orderData.status === 'pickup_requested' || orderData.status === 'return_requested'">
        <vs-button class="mt-6 font-semibold text-sm" v-if="editable" @click="cancelOrderNotesEdit()" type="border" color="secondary">{{$t('Cancel')}}</vs-button>
        <vs-button class="mt-6 font-semibold text-sm" v-if="editable" @click="updateOrderNotes()">{{$t('Save')}}</vs-button>
      </div>
    </div>
    <div class="flex gap-4 items-center justify-end" v-else-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'head-of-fleet' || $store.state.AppActiveUser.userRole === 'customer-support'">
      <vs-button class="mt-6 font-semibold text-sm" v-if="editable" @click="cancelOrderNotesEdit()" type="border" color="secondary">{{$t('Cancel')}}</vs-button>
      <vs-button class="mt-6 font-semibold text-sm" v-if="editable" @click="updateOrderNotes()">{{$t('Save')}}</vs-button>
    </div>
  </shipblu-card>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import {sendRequest} from '@/http/axios/requestHelper.js'
import i18nData from '../../../i18n/i18nData'
import common from '../../../assets/utils/common'

export default {
  props: ['orderData', 'notesCardLoading', 'merchantStatus'],
  data () {
    return {
      localStorage,
      editable: false
    }
  },
  methods: {
    editInformation () {
      this.editable = true
    },
    updateOrderNotes () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.notesCardLoading = true
          let orderNotesObj = {}
          orderNotesObj = {
            order_notes: this.orderData.order_notes,
            merchant_order_reference: this.orderData.merchant_order_reference ? this.orderData.merchant_order_reference : ''
          }
          sendRequest(true, false, this, `api/v1/${this.$route.params.type}/${this.$route.params.orderID}/`, 'patch', orderNotesObj, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Delivery Order'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
                position: 'top-center'
              })
              this.cancelOrderNotesEdit()
            },
            (error) => {
              common.notifyResponseError(this, error)
              this.$emit('loadOrder', 'notesCardLoading')
            }
          )
        }
      })
    },
    cancelOrderNotesEdit () {
      this.editable = !this.editable
      this.$emit('loadOrder', 'notesCardLoading')
    }
  },
  components: {
    ShipbluCard
  }
}
</script>
<template>
  <div class="lg:mb-0 mb-base" v-if="fulfillment.products.length > 0">
    <shipblu-card :cardLoading="fulfillingCardLoading" class="p-5">
      <h3>{{$t('Fulfilling with us?')}}</h3>
      <p class="text-sm font-medium">{{$t('We will automatically create a fulfillment order for you. Once pickup is requested, the fulfillment order is requested to ship.')}}</p>
      <div v-for="(item, index) in fulfillment.products" :key="index">
        <shipblu-card :class="!editable ? 'disabled-card' : ''" class="mt-4 p-5 relative">
          <div class="grid w-full md:grid-cols-3 grid-cols-1 sm:gap-4 gap-2">
            <div class="col-span-1">
              <v-select :class="item.fulfillment_center ? 'input-customer-info-filled remove-search' : ''" class="w-full input-customer-info"
                autocomplete="nofill" label="name" :name="`fulfillment center-${index}`" v-model="item.fulfillment_center" v-validate="'required'"
                :placeholder="$t('Fulfillment Center') + '*'" :options="fulfillmentCenters"/>
              <span class="text-danger text-sm" v-show="errors.has(`fulfillment center-${index}`)">{{ errors.first(`fulfillment center-${index}`) ? errors.first(`fulfillment center-${index}`).split(`-${index}`)[0] + errors.first(`fulfillment center-${index}`).split(`-${index}`)[1]  : '' }}</span>
            </div>
            <div class="col-span-1">
              <shipblu-v-select :class="item.product ? 'input-customer-info-filled' : ''" class="w-full input-customer-info product-dropdown" 
                autocomplete="nofill" label="name" optionalLabel="optionalLabel" :name="`product-${index}`" v-model="item.product" v-validate="'required'" 
                :placeholder="$t('Product') + '*'" :options="productCatalog" @search="handleProductSearch" @keyup.enter.native="handleProductSearch" search/>
              <span class="text-danger text-sm" v-show="errors.has(`product-${index}`)">{{ errors.first(`product-${index}`) ? errors.first(`product-${index}`).split(`-${index}`)[0] + errors.first(`product-${index}`).split(`-${index}`)[1]  : '' }}</span>
            </div>
             <div class="col-span-1 my-auto">
              <span class="text-lightgray mr-5">{{ $t('Quantity') }}:</span>
              <div class="inline-block">
                <vs-input-number v-if="$route.params.type === 'delivery-orders'" min="1" :max="item.product ? item.product.current_inventory_level : 1" v-validate="'required|numeric'" name="quantity" v-model="item.quantity" :value="0" class="stock-request-input-number flex justify-start text-sm"/>
                <vs-input-number v-else min="1" :max="item.product ? item.product.max : 1" v-validate="'required|numeric'" name="quantity" v-model="item.quantity" :value="0" class="stock-request-input-number flex justify-start"/>
                <span class="text-danger text-sm" v-show="errors.has('quantity')">{{ errors.first('quantity') }}</span>
              </div>
            </div>
            <div class="md:col-span-3 col-span-1">
              <v-select :class="item.flags.length !== 0 ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" 
                multiple autocomplete="nofill" label="name" :name="`flags-${index}`" v-model="item.flags" :placeholder="$t('Flags')" :options="productFlags"/>
            </div>
          </div>
          <div v-if="$store.state.AppActiveUser.userRole === 'merchant' && fulfillment.products.length > 1" @click="removeProduct(index)" class="cursor-pointer rounded-md remove-icon-position shadow-md flex items-center p-1">
            <feather-icon :class="editable ? 'text-danger' : 'text-lightgray'" icon="Trash2Icon" svgClasses="h-5 w-5" />
          </div>
        </shipblu-card>
      </div>
      <div v-if="editable && fulfillment.products.length > 0" class="relative flex items-center justify-end mt-6">
        <div class="absolute add-products">
          <span class="text-sm font-medium py-1 px-2 rounded tooltip bg-white text-primary">{{$t('Add more products')}}</span>
        </div>
        <div class="p-1 flex self-center bg-primary rounded">
          <feather-icon @click="addProduct()" class="cursor-pointer" icon="PlusIcon" svgClasses="w-5 h-5 text-white" />
        </div>
      </div>
    </shipblu-card>
  </div>
</template>

<script>
import {sendFulfillmentRequest} from '../../../http/axios/requestHelper.js'
import vSelect from 'vue-select'
import common from '../../../assets/utils/common.js'
import ShipbluVSelect from 'shipblu-vue-select'
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'

export default {
  props: ['merchantID', 'fulfillment', 'orderData', 'fulfillingCardLoading'],
  data () {
    return {
      editable: false,
      products: [],
      allProductsList: [],
      productCatalog: [],
      productFlags: [],
      fulfillmentCenters: [],
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      outboundProducts: []
    }
  },
  watch: {
    'fulfillment.products': {
      handler (val) {
        const choosedProducts = []
        this.productCatalog = []
        this.allProductsList.forEach(element => {
          this.productCatalog.push(element)
        })
        val.forEach(item => {
          if (item.product && item.product !== null) {
            item.product.optionalLabel = `SKU: ${item.product.sku} . ${item.product.current_inventory_level} available`
            choosedProducts.push(item.product)
            if (this.$route.params.type === 'delivery-orders') {
              item.quantity = (item.quantity > item.product.current_inventory_level) ? item.product.current_inventory_level : item.quantity
            } else if (this.$route.params.type === 'returns' && this.outboundProducts && this.outboundProducts.length > 0) {
              this.outboundProducts[0].products.forEach(element => {
                val.forEach(item => {
                  if (item.product && (item.product.id === element.product.id)) {
                    item.product.max = element.quantity
                  }
                })
              })
              item.quantity = (item.quantity > item.product.max) ? item.product.max : item.quantity
            }
          }
        })
        for (let i = 0; i < choosedProducts.length; i++) {
          this.productCatalog.forEach(element => {
            if (element.id === choosedProducts[i].id) {
              this.productCatalog.splice(this.productCatalog.indexOf(element), 1)
            }
          })
        }
      },
      deep: true
    },
    'searchVal' (val) {
      if (val === '') {
        this.loadProductCatalog()
      }
    }
  },
  methods: {
    handleProductSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadProductsSearch, 1000)
    },
    loadProductsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      if (this.searchVal) {
        this.loadProductCatalog()
      }
    },
    loadProductCatalog () {
      this.searchInProgress = true
      const url = `${this.$route.params.type === 'returns' ? `api/v1/fc/orders/outbound/?merchant=${this.merchantID}&status=shipped&search=${this.orderData.customer.phone}&limit=100` : `api/v1/fc/products/?merchant=${this.merchantID}&current_inventory_level__gt=0&search=${this.searchVal}&limit=100`}`
      sendFulfillmentRequest(true, false, this, `${url}`, 'get', null, true,
        (response) => {
          this.allProductsList = []
          if (this.$route.params.type === 'delivery-orders') {
            this.allProductsList = response.data.results
          } else {
            this.outboundProducts = response.data.results
            this.outboundProducts[0].products.forEach(element => {
              this.fulfillment.products.forEach(item => {
                if (item.product.id === element.product.id) {
                  item.product.max = element.quantity
                }
              })
              this.allProductsList.push(element.product)
            })
          }
          this.productCatalog = []
          this.allProductsList.forEach(element => {
            this.productCatalog.push(element)
          })
          for (let i = 0; i < this.fulfillment.products.length; i++) {
            this.productCatalog.forEach(element => {
              if (element.id === this.fulfillment.products[i].product.id) {
                this.productCatalog.splice(this.productCatalog.indexOf(element), 1)
              }
            })
          }
          this.allProductsList.map(item => {
            item.optionalLabel = `SKU: ${item.sku} . ${item.current_inventory_level} available`
          })
          this.searchedValue = this.searchVal
          this.fulfillingCardLoading = false
        }
      )
      this.searchInProgress = false
    },
    loadFulfillmentCenters () {
      sendFulfillmentRequest(true, false, this, 'api/v1/fc/fulfillment-centers/', 'get', null, true,
        (response) => {
          this.fulfillmentCenters = response.data.results
        }
      )
    },
    loadProductFlags () {
      sendFulfillmentRequest(true, false, this, 'api/v1/fc/flags/', 'get', null, true,
        (response) => {
          this.productFlags = response.data.results
        }
      )
    },
    addProduct () {
      this.fulfillment.products.push({
        fulfillment_center: '',
        product: '',
        flags: [],
        quantity: 1
      })
    }
  },
  components: {
    vSelect,
    ShipbluVSelect,
    ShipbluCard
  }
}
</script>

<style scoped>
.disabled-card {
  pointer-events: none;
}
.trash {
  position: absolute;
  bottom: 10px;
  right: 10px;
  border-radius: 5px;
}
</style>
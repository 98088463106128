<template>
  <shipblu-prompt
    class="ringer-modal"
    @close="closeModal()"
    :active.sync="localRingerModal"
    :title="$t('Local Ringer')"
    :buttons-hidden="true">
    <div class="ringer-table">
      <table>
        <thead>
          <tr>
            <th>{{$t('Call')}}</th>
            <th>{{$t('Direction')}}</th>
            <th>{{$t('Phone')}}</th>
            <th>{{$t('Call in seconds')}}</th>
            <th>{{$t('Ringing in seconds')}}</th>
            <th>{{$t('Timestamp')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in attemptCalls" :key="index">
            <td>{{ index + 1 }}</td>
            <td class="capitalize">{{ item.direction }}</td>
            <td class="capitalize">{{ item.phone ? item.phone : 'N/A' }}</td>
            <td>{{ item.call_in_seconds + ' ' + $t('Seconds')}}</td>
            <td>{{ item.ringing_in_seconds + ' ' + $t('Seconds')}}</td>
            <td>
              <p>{{ new Date(item.created).toLocaleDateString('fr-CA') }}</p>
              <p>{{ new Date(item.created).toLocaleTimeString() }}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </shipblu-prompt>
</template>

<script>
import ShipbluPrompt from '@/layouts/components/ShipBluPrompt.vue'

export default {
  props: ['localRingerModal', 'attemptCalls'],
  methods: {
    closeModal () {
      this.$emit('localRingerModal', false)
    }
  },
  components: {
    ShipbluPrompt
  }
}
</script>

<style lang="scss">
div.ringer-modal {
  .vs-dialog{
    max-width: 700px !important;
  }
  .ringer-table {
    overflow-x: auto;
    padding-bottom: 8px;
    table {
      border-collapse: collapse;
      width: 100%;
      border: 1.63px solid #DFDFDF;
      th, td {
        &:first-child {
          border: 1.63px solid #DFDFDF;
          text-align: center;
        }
        white-space: nowrap;
        text-align: left;
        border-bottom: 1.63px solid #DFDFDF;
        padding: 12px;
        color: #797979;
        font-weight: 500;
        font-size: 14px;
      }
      td {
        color: #444444;
      }
    }
  }
}
</style>
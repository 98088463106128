<template>
  <div class="mt-10 live-tracking">
    <div v-for="(item, index) in orderLiveTracking" :key="index" class="tracking-time-line">
      <div v-if="index !== 0" :class="`line ${item.active ? 'active-line' : 'inactive-line'}`"></div>
      <p :class="`material-icons-round icon text-2xl ${item.active ? 'text-primary' : 'text-grey'}`">check_circle</p>
      <div class="md:mb-2 mb-1 md:mx-1">
        <p class="inline-block whitespace-nowrap text-white text-sm capitalize py-1 px-2 rounded-4xl" :style="{background: item.active ? item.color : '#B8C2CC'}">
          {{ $t(item.name) }}
        </p>
      </div>
      <div class="md:block flex gap-2 justify-between items-center">
        <p v-if="$store.state.AppActiveUser.userRole !== 'merchant'" class="text-sm text-darkgray font-medium">
          {{ trackings.filter(element => item.statuses && item.statuses.includes(element.status) && element.user).length > 0 ? trackings.slice().reverse().filter(element => item.statuses && item.statuses.includes(element.status) && element.user)[0].user.first_name + ' ' + 
          trackings.slice().reverse().filter(element => item.statuses && item.statuses.includes(element.status) && element.user)[0].user.last_name : '' }}
        </p>
        <div v-if="(item.name === 'delivered' || item.name === 'returned') && (orderData.status !== 'delivered' && orderData.status !== 'returned')" class="text-xs text-darkgray shrink-0">
          <p>{{ orderData.estimated_date ? orderData.estimated_date : '' }}</p>
          <p>{{ orderData.estimated_date ? $t('expected delivery date') : '' }}</p>
        </div>
        <p v-else class="text-xs text-darkgray shrink-0">
          {{ item.name === 'on hold' ? new Date(orderData.on_hold).toLocaleDateString('fr-CA') + ' ' + new Date(orderData.on_hold).toLocaleTimeString() : 
             item.name === 'received' && orderData.pickup_date && !['pickup_requested', 'pickup_rescheduled', 'out_for_pickup', 'created', 'cancelled'].includes(orderData.status) ? new Date(orderData.pickup_date).toLocaleDateString('fr-CA') + ' ' + new Date(orderData.pickup_date).toLocaleTimeString() : 
             trackings.filter(element => item.statuses && item.statuses.includes(element.status)).length > 0 ? 
              new Date(trackings.slice().reverse().filter(element => item.statuses && item.statuses.includes(element.status))[0].created).toLocaleDateString('fr-CA') + ' ' +
              new Date(trackings.slice().reverse().filter(element => item.statuses && item.statuses.includes(element.status))[0].created).toLocaleTimeString() : '' }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['orderData', 'trackings'],
  data () {
    return {
      orderLiveTracking: [
        {
          name: 'requested',
          statuses: ['pickup_requested', 'pickup_rescheduled', 'out_for_pickup'],
          color: '#808000',
          active: false
        },
        {
          name: 'received',
          statuses: ['picked_up'],
          color: '#65DCA1',
          active: false
        },
        {
          name: 'on its way',
          statuses: ['in_transit', 'en_route'],
          color: '#1c5bfe',
          active: false
        },
        {
          name: 'out for delivery',
          statuses: ['out_for_delivery'],
          color: '#C7B060',
          active: false
        },
        {
          name: 'delivery attempted',
          statuses: ['delivery_attempted'],
          color: '#E6194B',
          active: false
        },
        {
          name: 'on hold',
          color: '#E6194B',
          active: false
        },
        {
          name: 'return to origin',
          statuses: ['out_for_return', 'return_to_origin'],
          color: '#E6194B',
          active: false
        },
        {
          name: 'delivered',
          statuses: ['delivered'],
          color: '#17814C',
          active: false
        },
        {
          name: 'returned',
          statuses: ['returned'],
          color: '#17814C',
          active: false
        },
        {
          name: 'lost',
          statuses: ['lost'],
          color: '#E6194B',
          active: false
        },
        {
          name: 'damaged',
          statuses: ['damaged'],
          color: '#E6194B',
          active: false
        },
        {
          name: 'forfeited',
          statuses: ['forfeited'],
          color: '#E6194B',
          active: false
        }
      ],
      showStatuses: ['requested', 'received', 'on its way']
    }
  },
  methods: {
    showTimline () {
      let orderStatus = ''
      if (['delivered', 'returned', 'lost', 'damaged', 'forfeited'].includes(this.orderData.status)) {
        orderStatus = this.orderData.status
      } else {
        orderStatus = this.orderData.on_hold ? 'on_hold' : this.orderData.rto_requested ? 'rto_requested' : this.orderData.status
      }
      
      switch (orderStatus) {
      case 'delivered':
        this.showStatuses = this.orderData.delivery_attempts === 0 ? [...this.showStatuses, 'out for delivery', 'delivered'] : [...this.showStatuses, 'delivery attempted', 'delivered']
        this.setTimelineItems(this.showStatuses, 5)
        break

      case 'returned':
        this.showStatuses = [...this.showStatuses, 'return to origin', 'returned']
        this.setTimelineItems(this.showStatuses, 5)
        break

      case 'lost':
      case 'damaged':
      case 'forfeited':
        this.showStatuses = [...this.showStatuses, 'out for delivery', this.orderData.status]
        this.setTimelineItems(this.showStatuses, 5)
        break

      case 'on_hold':
        this.showStatuses = [...this.showStatuses, 'on hold', 'delivered']
        this.setTimelineItems(this.showStatuses, 4)
        break

      case 'return_to_origin':
      case 'rto_requested':
      case 'out_for_return':
        this.showStatuses = [...this.showStatuses, 'return to origin', 'returned']
        this.setTimelineItems(this.showStatuses, 4)
        break

      case 'out_for_delivery':
        this.showStatuses = [...this.showStatuses, 'out for delivery', 'delivered']
        this.setTimelineItems(this.showStatuses, 4)
        break

      case 'in_transit':
      case 'en_route':
      case 'delivery_attempted':
        this.showStatuses = this.orderData.delivery_attempts === 0 ? [...this.showStatuses, 'out for delivery', 'delivered'] : [...this.showStatuses, 'delivery attempted', 'delivered']
        this.setTimelineItems(this.showStatuses, this.orderData.delivery_attempts === 0 ? 3 : 4)
        break

      case 'picked_up':
        this.showStatuses = [...this.showStatuses, 'out for delivery', 'delivered']
        this.setTimelineItems(this.showStatuses, 2)
        break

      case 'pickup_requested':
      case 'pickup_rescheduled':
      case 'out_for_pickup':
        this.showStatuses = [...this.showStatuses, 'out for delivery', 'delivered']
        this.setTimelineItems(this.showStatuses, 1)
        break

      case 'created':
      case 'cancelled':
        this.showStatuses = [...this.showStatuses, 'out for delivery', 'delivered']
        this.setTimelineItems(this.showStatuses, 0)
        break

      default:
        break
      }
    },
    setTimelineItems (items, activeItemsNumber) {
      this.orderLiveTracking = this.orderLiveTracking.filter(item => items.includes(item.name))
      this.orderLiveTracking.slice(0, activeItemsNumber).forEach(item => { item.active = true })
    }
  },
  mounted () {
    this.showTimline()
  }
}
</script>
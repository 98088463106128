<template>
  <shipblu-card :cardLoading="orderInfoCardLoading" class="p-5">
    <div class="flex justify-between items-center">
      <p class="header">{{ $t('Order Info') }}</p>
      <div v-if="$store.state.AppActiveUser.userRole === 'merchant' && merchantStatus === 'active'">
        <div class="flex" v-if="orderData.status === 'created' || orderData.status === 'return_requested' || orderData.status === 'pickup_requested'">
          <feather-icon icon="EditIcon" svgClasses="h-5 w-5" class="text-primary cursor-pointer" 
            v-if="!editable" @click="editInformation()" />
        </div>
      </div>
      <div class="flex" v-else-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'head-of-fleet' || $store.state.AppActiveUser.userRole === 'customer-support'">
        <feather-icon icon="EditIcon" svgClasses="h-5 w-5" class="text-primary cursor-pointer" 
          v-if="!editable && !(orderData.status === 'delivered' || orderData.status === 'returned' || orderData.status === 'returned_to_origin' || orderData.status === 'cancelled')" @click="editInformation()" />
      </div>
    </div>
    <div class="mt-4 grid sm:grid-cols-2 grid-cols-1 sm:gap-x-6 gap-4 relative">
      <div class="col-span-1">
        <div class="mb-4" v-if="$store.state.AppActiveUser.userRole !== 'merchant'">
          <div class="flex items-center gap-x-2">
            <span class="material-icons text-xl text-grey">add_business</span>
            <p class="font-medium text-darkgray text-sm leading-tight">{{ $t('Merchant Name') }}</p>
          </div>
          <div class="font-medium pl-6 mt-1">
            <router-link target="_blank" :to="{ name: `${$store.state.AppActiveUser.userRole}-merchant-view`, params: {tab: 'info', merchantID: orderData.merchant.id } }" v-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'sales' || $store.state.AppActiveUser.userRole === 'customer-support' || $store.state.AppActiveUser.userRole === 'finance'">
              {{ orderData.merchant ? orderData.dba ? orderData.dba + ' - ' + orderData.merchant.name : orderData.merchant.name : 'N/A' }} {{ orderData.merchant.display_name ?  '- ' +  orderData.merchant.display_name : '' }}
            </router-link>
            <p v-else class="text-black">{{ orderData.merchant ? orderData.dba ? orderData.dba + ' - ' + orderData.merchant.name : orderData.merchant.name : 'N/A' }} {{ orderData.merchant.display_name ?  '- ' +  orderData.merchant.display_name : '' }}</p>
          </div>
        </div>
        <div>
          <div class="flex items-center gap-x-2">
            <feather-icon icon="TruckIcon" svgClasses="w-4 h-4" class="text-grey" />
            <p class="font-medium text-darkgray text-sm leading-tight">{{ $t('Tracking Number') }}</p>
          </div>
          <p class="text-black font-medium pl-6 mt-1">{{ orderData.tracking_number }}</p>
        </div>
        <div v-if="$route.params.type !== 'cash-collections'" class="mt-4">
          <div class="flex items-center gap-x-2">
            <feather-icon icon="CalendarIcon" svgClasses="w-4 h-4" class="text-grey" />
            <p class="font-medium text-darkgray text-sm leading-tight">{{ $t('Picked up On') }}</p>
          </div>
          <p class="text-black font-medium pl-6 mt-1">{{ orderData.pickup_date ? new Date(orderData.pickup_date).toLocaleDateString('fr-CA') : 'N/A' }}</p>
        </div>
        <div v-if="$route.params.type !== 'cash-collections'" class="mt-4">
          <div class="flex items-center gap-x-2">
            <feather-icon icon="CalendarIcon" svgClasses="w-4 h-4" class="text-grey" />
            <p class="font-medium text-darkgray text-sm leading-tight">{{ $route.params.type === 'returns' ? $t('Estimated Pickup Date') : $t('Estimated Delivery Date') }}</p>
          </div>
          <p class="text-black font-medium pl-6 mt-1">{{ orderData.estimated_date? orderData.estimated_date : 'N/A' }}</p>
        </div>
        <div v-if="$route.params.type !== 'cash-collections'" class="mt-4">
          <div class="flex items-center gap-x-2">
            <feather-icon icon="CalendarIcon" svgClasses="w-4 h-4" class="text-grey" />
            <p class="font-medium text-darkgray text-sm leading-tight">{{ $route.params.type === 'returns' ? $t('Preferred Pickup Date') : $t('Preferred Delivery Date') }}</p>
          </div>
          <p v-show="!editable" class="text-black font-medium pl-6 mt-1">{{ orderData.preferred_date ? orderData.preferred_date : 'N/A' }}</p>
          <div class="pl-6 mt-1">
            <div v-if="orderData.visit_dates && orderData.visit_dates.length && orderData.visit_dates.length > 0 && $store.state.AppActiveUser.userRole === 'customer-support'" v-show="editable">
              <datepicker name="date" :disabledDates="disabledDates" :placeholder="$t('Date') + '*'" v-model="orderData.preferred_date" class="w-full"></datepicker>
              <span class="text-danger text-sm" v-show="errors.has('date')">{{ errors.first('date') }}</span>
            </div>
          </div>
        </div>
        <div v-if="$route.params.type !== 'cash-collections'" class="mt-4">
          <div class="flex items-center gap-x-2">
            <feather-icon icon="CalendarIcon" svgClasses="w-4 h-4" class="text-grey" />
            <p class="font-medium text-darkgray text-sm leading-tight">{{ $route.params.type === 'returns' ? $t('Last Date for Pickup') : $t('Last Date for Delivery') }}</p>
          </div>
          <p class="text-black font-medium pl-6 mt-1">{{ orderData.last_visit_date ? orderData.last_visit_date : 'N/A' }}</p>
        </div>
      </div>
      <div class="separator-line sm:block hidden"></div>
      <div class="col-span-1">
        <div v-if="$route.params.type !== 'cash-collections'">
          <div class="flex items-center gap-x-2">
              <feather-icon icon="PackageIcon" svgClasses="w-4 h-4" class="text-grey" />
              <p class="font-medium text-darkgray text-sm leading-tight">{{ 'Order Type'}}</p>
          </div>
          <div class="items-center mt-2" v-if="$store.state.AppActiveUser.userRole === 'customer-support'">
            <vs-switch v-model="orderData.is_counter_dropoff" :disabled="!editable || !($route.params.type === 'returns' && orderData.tracking_events.filter(item => item.status === 'in_transit' || item.status === 'picked_up').length > 0) && !($route.params.type === 'delivery-orders' && orderData.rto_requested)">
              <span slot="on">Counter DropOff</span>
              <span slot="off">Pickup Requested</span>
            </vs-switch>
          </div>
          <div class="items-center mt-2" v-else>
            <vs-switch style="" :disabled="!(editable && $store.state.AppActiveUser.userRole === 'merchant' && orderData.status === 'created')" v-model="orderData.is_counter_dropoff">
              <span slot="on">Counter DropOff</span>
              <span slot="off">Pickup Requested</span>
            </vs-switch>
          </div>
          <div class="mt-3" v-if="!orderData.is_counter_dropoff">
            <div class="flex items-center gap-x-2">
              <feather-icon icon="MapPinIcon" svgClasses="w-4 h-4" class="text-grey" />
              <p class="font-medium text-darkgray text-sm leading-tight">{{ $route.params.type === 'returns' ? $t('DropOff Point') : $t('Pickup Point') }}</p>
            </div>
            <div class="pl-6 mt-1">
              <p v-show="!editable" class="text-black font-medium">{{ orderData.pickup_point }}</p>
              <div v-show="editable">
                <v-select class="select-large w-full" v-model="orderData.pickup_point" v-validate="'required'" name="pickup point" label="fullAddress" :options="pickupPoints"/>
                <span class="text-danger text-sm" v-show="errors.has('pickup point')">{{ errors.first('pickup point') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="$route.params.type === 'returns' || $route.params.type === 'delivery-orders'" class="mt-4">
          <div v-if="orderData.return_point !== orderData.pickup_point">
            <div class="flex items-center gap-x-2">
              <feather-icon icon="MapPinIcon" svgClasses="w-4 h-4" class="text-grey" />
              <p class="font-medium text-darkgray text-sm leading-tight">{{ $t('Return Point') }}</p>
            </div>
            <div class="pl-6 mt-1">
              <p v-show="!editable " class="text-black font-medium">{{ orderData.return_point }}</p>
              <div v-show="editable">
                <v-select class="select-large w-full" v-model="orderData.return_point" name="return point" label="fullAddress" :options="returnPoints"/>
                <span class="text-danger text-sm" v-show="errors.has('return point')">{{ errors.first('return point') }}</span>
              </div>
            </div>
          </div>
          <div v-show="editable && orderData.return_point === orderData.pickup_point">
            <div class="flex items-center gap-x-2">
                <feather-icon icon="MapPinIcon" svgClasses="w-4 h-4" class="text-grey" />
                <p class="font-medium text-darkgray text-sm leading-tight">{{ $t('Return Point') }}</p>
            </div>
            <div class="pl-6 mt-1">
                <v-select class="select-large w-full" v-model="orderData.return_point" name="return point" label="fullAddress" :options="returnPoints"/>
                <span class="text-danger text-sm" v-show="errors.has('return point')">{{ errors.first('return point') }}</span>
            </div>
          </div>
        </div>
        <div v-if="$route.params.type === 'cash-collections'">
          <div class="flex items-center gap-x-2">
            <feather-icon icon="CalendarIcon" svgClasses="w-4 h-4" class="text-grey" />
            <p class="font-medium text-darkgray text-sm leading-tight">{{ $t('Preferred Delivery Date') }}</p>
          </div>
          <p class="text-black font-medium pl-6 mt-1">{{ orderData.preferred_date ? orderData.preferred_date : 'N/A' }}</p>
        </div>
        <div v-if="$route.params.type === 'cash-collections' && $store.state.AppActiveUser.userRole !== 'head-of-fleet' && $store.state.AppActiveUser.userRole !== 'manager'" class="mt-4">
          <div class="flex items-center gap-x-2">
            <feather-icon icon="DollarSignIcon" svgClasses="w-4 h-4" class="text-grey" />
            <p class="font-medium text-darkgray text-sm leading-tight">{{ $t('Amount') }}</p>
          </div>
          <div class="pl-6 mt-1">
            <p v-show="!editable || localStorage.getItem('multipleWarehouses') !== 'true'" class="text-black font-medium">{{ parseInt(orderData.amount) > 0 ? parseInt(orderData.amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00' }}</p>
            <div v-show="editable && localStorage.getItem('multipleWarehouses') === 'true'">
              <vs-input v-validate="'required'" name="amount" :placeholder="$t('Amount')" v-model="orderData.amount" class="w-full order-view-input"/>
              <span class="text-danger text-sm" v-show="errors.has('amount')">{{ errors.first('amount') }}</span>
            </div>
          </div>
        </div>
        <div v-if="$route.params.type !== 'cash-collections' && $store.state.AppActiveUser.userRole !== 'head-of-fleet' && $store.state.AppActiveUser.userRole !== 'manager'" class="mt-4">
          <div class="flex items-center gap-x-2">
            <feather-icon icon="DollarSignIcon" svgClasses="w-4 h-4" class="text-grey" />
            <p  class="flex font-medium text-darkgray text-sm leading-tight">
              {{ $route.params.type === 'delivery-orders' ? $t('CoD') : $t('Cash Refund') }}
              <span v-if="$store.state.AppActiveUser.userRole === 'customer-support'">
                <span v-if="orderData.is_paid && orderData.payment_method === null" class="material-symbols-outlined text-2xl">credit_card</span>
                <img v-else class="ml-2 w-6" :src="payType" alt="">
              </span>
            </p>
            <span v-if="orderData.is_paid && $store.state.AppActiveUser.userRole !== 'customer-support'" class="material-symbols-outlined text-2xl">credit_card</span>
          </div>
          <div class="pl-6 mt-1">
            <p v-show="!editable || localStorage.getItem('multipleWarehouses') !== 'true'" class="text-black font-medium">
              {{ orderData.cash_amount && parseInt(orderData.cash_amount) > 0 && !editable ? (orderData.cash_amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' ' + $t('EGP') : 'N/A' }}
            </p>
            <div v-show="editable && localStorage.getItem('multipleWarehouses') === 'true'">
              <vs-input v-validate="'required|decimal'" name="amount" v-model="orderData.cash_amount" class="w-full order-view-input"/>
              <p class="text-danger text-sm" v-show="errors.has('amount')">{{ errors.first('amount') }}</p>
              <p class="text-sm">{{ $t('Please, do not forget to add any applicable fees') }} {{ $route.params.type === 'delivery-orders' ? $t('(delivery, CoD) to CoD Amount!') : $t('(return, refund) to return Amount!') }}</p>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <div class="flex items-center gap-x-2">
            <feather-icon icon="CalendarIcon" svgClasses="w-4 h-4" class="text-grey" />
            <p class="font-medium text-darkgray text-sm leading-tight">{{ $route.params.type === 'returns' ? $t('Pickup Window') : $t('Delivery Window') }}</p>
          </div>
          <div v-if="orderData.preferred_window && orderData.preferred_window.length !== 0">
            <div v-for="(preferredWindow, index) in orderData.preferred_window" :key="index">
              <p class="text-black font-medium pl-6 mt-1">{{ preferredWindow.display_name }}</p>
            </div>
          </div>
          <p v-else class="text-black font-medium pl-6 mt-1">{{ 'N/A' }}</p>
        </div>
        <div class="mt-4">
          <div class="flex items-center gap-x-2">
            <feather-icon icon="PackageIcon" svgClasses="w-4 h-4" class="text-grey" />
            <p class="font-medium text-darkgray text-sm leading-tight">{{ $t('Status') }}</p>
          </div>
          <div class="flex">
            <p v-if="orderData.status" class="font-medium pl-6 mt-1 capitalize" :style="{color: getOrderStatusColor(orderData)}">{{ $t(getStatusLabel(orderData)) }}</p>
            <p v-if="orderData.on_hold" class="ml-3 inline-block whitespace-nowrap text-white text-sm capitalize py-1 px-2 rounded-4xl" :style="{background: '#E6194B'}">
              {{ 'On hold' }}
            </p>
          </div>
        </div>
        <div v-if="$route.params.type !== 'cash-collections' && insurance.insurance_package && insurance.insurance_package.id !== 1" class="mt-4">
          <div class="flex items-center gap-x-2">
            <feather-icon icon="DollarSignIcon" svgClasses="w-4 h-4" class="text-grey" />
            <p class="font-medium text-darkgray text-sm leading-tight">{{ $t('Products declared value') }}</p>
          </div>
          <div class="pl-6 mt-1">
            <p v-show="!editable || localStorage.getItem('multipleWarehouses') !== 'true'" class="text-black font-medium">
              {{ orderData.declared_value && parseInt(orderData.declared_value) > 0 && !editable ? (orderData.declared_value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' ' + $t('EGP') : 'N/A' }}
            </p>
            <div v-show="editable || localStorage.getItem('multipleWarehouses') !== 'true'">
              <div v-if="$store.state.AppActiveUser.userRole === 'merchant'">
                <vs-input v-validate="'required|decimal'" name="declaredvalue" v-model="orderData.declared_value" :placeholder="$t('Products declared value')" class="w-full order-view-input"/>
                <p class="text-danger text-sm" v-show="errors.has('declaredvalue')">{{ errors.first('declaredvalue') }}</p>
                <p class="text-sm">{{$t('Insurance fees will be applied on this amount in case the COD amount = 0')}}</p>
              </div>
              <p v-else class="text-black font-medium">
                {{ orderData.declared_value && parseInt(orderData.declared_value) > 0 ? (orderData.declared_value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' ' + $t('EGP') : 'N/A' }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$store.state.AppActiveUser.userRole === 'merchant'">
      <div class="flex gap-4 items-center justify-end" v-if="orderData.status === 'created'|| orderData.status === 'pickup_requested' || orderData.status === 'return_requested'">
        <vs-button class="mt-6 font-semibold text-sm" v-if="editable" @click="cancelOrderInformationEdit()" type="border" color="secondary">{{$t('Cancel')}}</vs-button>
        <vs-button class="mt-6 font-semibold text-sm" v-if="editable" @click="updateOrderInfo()">{{$t('Save')}}</vs-button>
      </div>
    </div>
    <div class="flex gap-4 items-center justify-end" v-else-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'head-of-fleet' || $store.state.AppActiveUser.userRole === 'customer-support'">
      <vs-button class="mt-6 font-semibold text-sm" v-if="editable" @click="cancelOrderInformationEdit()" type="border" color="secondary">{{$t('Cancel')}}</vs-button>
      <vs-button class="mt-6 font-semibold text-sm" v-if="editable" @click="updateOrderInfo()">{{$t('Save')}}</vs-button>
    </div>
  </shipblu-card>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import { sendRequest } from '../../../http/axios/requestHelper'
import vSelect from 'vue-select'
import i18nData from '../../../i18n/i18nData'
import common from '../../../assets/utils/common'
import Datepicker from 'vuejs-datepicker'
import cod from '../../../assets/images/pages/cash.svg'
import epayment_cards from '../../../assets/images/pages/visa.svg'
import flash from '../../../assets/images/pages/flash-pay.svg'
import valu from '../../../assets/images/pages/valu.svg'

export default {
  props: ['orderData', 'isCounterDropOff', 'orderInfoCardLoading', 'insurance', 'merchantStatus'],
  data () {
    return {
      localStorage,
      editable: false,
      returnPoints: [],
      pickupPoints: [],
      disabledDates: {
        days: [5]
      },
      payType: ''
    }
  },
  watch: {
    'editable' (val) {
      if (val) {
        this.disabledDates.customPredictor = (date) => {
          if (!this.orderData.visit_dates.includes(date.toISOString().split('T')[0])) {
            return true
          }
        }
      }
    },
    'orderData' () {
      this.payTypeImg()
    }
  },
  methods: {
    payTypeImg () {
      switch (this.orderData.payment_method) {
      case 'cod':
        this.payType = cod
        break
      case 'upg':
        this.payType = epayment_cards
        break
      case 'epayment_cards':
        this.payType = epayment_cards
        break
      case 'flash':
        this.payType = flash
        break
      case 'valu':
        this.payType = valu
        break
      default:
        this.payType = ''
      }
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order.status ? order : order.status, this.$store.state.AppActiveUser.userRole)
    },
    editInformation () {
      this.orderInfoCardLoading = true
      this.loadPickupPoints(this.orderData.merchant.id)
      this.loadReturnPoints(this.orderData.merchant.id)
      this.editable = true
    },
    loadPickupPoints (merchantID) {
      sendRequest(true, false, this, `api/v1/merchants/${merchantID}/pickup-points/`, 'get', null, true, 
        (response) => {
          this.pickupPoints = response.data.results
          this.pickupPoints.forEach(element => {
            element.fullAddress = element.address.line_1 + element.address.line_2 + element.address.zone.name
          })
          this.orderInfoCardLoading = false
        }
      )
    },
    loadReturnPoints (merchantID) {
      sendRequest(true, false, this, `api/v1/merchants/${merchantID}/return-points/`, 'get', null, true, 
        (response) => {
          this.returnPoints = response.data.results
          this.returnPoints.forEach(element => {
            element.fullAddress = element.address.line_1 + element.address.line_2 + element.address.zone.name
          })
          this.orderInfoCardLoading = false
        }
      )
    },
    updateOrderInfo () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.orderInfoCardLoading = true
          let orderObj = {}
          if (this.$route.params.type === 'cash-collections') {
            orderObj = {
              amount: this.orderData.amount
            }
          } else {
            orderObj = {
              is_counter_dropoff: this.orderData.is_counter_dropoff,
              declared_value: Number(this.orderData.declared_value),
              preferred_date: this.orderData.preferred_date ? String(this.orderData.preferred_date).includes('T') ? this.orderData.preferred_date.toISOString().split('T')[0] : this.orderData.preferred_date : null
            }
            if (Number(this.orderData.cash_amount) !== Number(this.orderData.cash)) orderObj.cash_amount = Number(this.orderData.cash_amount)
            if (this.orderData.return_point) orderObj.return_point = this.orderData.return_point.id
            if (this.orderData.pickup_point) orderObj.pickup_point = this.orderData.pickup_point.id
          }
          sendRequest(true, false, this, `api/v1/${this.$route.params.type}/${this.$route.params.orderID}/`, 'patch', orderObj, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Delivery Order'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
                position: 'top-center'
              })
              this.cancelOrderInformationEdit()
            },
            (error) => {
              common.notifyResponseError(this, error)
              this.$emit('loadOrder', 'orderInfoCardLoading')
            }
          )
        }
      })
    },
    cancelOrderInformationEdit () {
      this.editable = !this.editable
      this.$emit('loadOrder', 'orderInfoCardLoading')
    }
  },
  components: {
    ShipbluCard,
    vSelect,
    Datepicker
  }
}
</script>
<template>
  <shipblu-card :cardLoading="packageInfoCardLoading" class="p-5">
    <div class="flex justify-between items-center">
      <p class="header">{{ $t('Packages Info') }}</p>
      <div v-if="$store.state.AppActiveUser.userRole === 'merchant' && merchantStatus === 'active'">
        <div class="flex" v-if="orderData.status === 'created' || orderData.status === 'return_requested' || orderData.status === 'pickup_requested'">
          <feather-icon icon="EditIcon" svgClasses="h-5 w-5" class="text-primary cursor-pointer" 
            v-if="!editable" @click="editInformation()" />
        </div>
      </div>
      <div class="flex" v-else-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'head-of-fleet' || $store.state.AppActiveUser.userRole === 'customer-support'">
        <feather-icon icon="EditIcon" svgClasses="h-5 w-5" class="text-primary cursor-pointer" 
          v-if="!editable && !(orderData.status === 'delivered' || orderData.status === 'returned' || orderData.status === 'returned_to_origin' || orderData.status === 'cancelled')" @click="editInformation()" />
      </div>
    </div>
    <div class="mt-4">
      <div v-for="(item, index) in orderData.packages" :key="index">
        <div class="package-card grid sm:grid-cols-2 col-span-1 gap-3 p-3 mt-4">
          <div class="col-span-1">
            <p class="font-medium text-darkgray text-sm leading-tight">{{ $t('Supplies type') }}</p>
            <v-select :disabled="!editable || localStorage.getItem('multipleWarehouses') !== 'true'" class="select-large mt-1 w-full" v-model="item.package.package_size"
              :name="`package size-${index}`" v-validate="'required'" :placeholder="$t('Package Size') + '*'" label="name" :options="packageSizes"/>
            <span class="text-danger text-sm" v-show="editable && errors.has(`package size-${index}`)">{{ errors.first(`package size-${index}`) ? errors.first(`package size-${index}`).split(`-${index}`)[0] + errors.first(`package size-${index}`).split(`-${index}`)[1]  : '' }}</span>
          </div>
          <div class="col-span-1">
            <p class="font-medium text-darkgray text-sm leading-tight">{{ $t('Description') }}</p>
            <vs-input :disabled="!editable || localStorage.getItem('multipleWarehouses') !== 'true'" :placeholder="$t('Description')" v-model="item.package.description" class="w-full mt-1 order-view-input"/>
          </div>
          <div class="col-span-1">
            <div class="flex gap-1 items-center">
              <vs-switch style="transform: scale(0.9);" :disabled="!editable || localStorage.getItem('multipleWarehouses') !== 'true'" v-model="item.package.fragile"/>
              <p :class="item.package.fragile ? 'text-primary' : 'text-grey'" class="font-medium leading-tight">{{ $t('Fragile/Hazardous') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="orderData.status === 'created' && $route.params.type === 'delivery-orders' && this.orderData.packages.length < 4" class="relative flex items-center justify-end mt-4">
        <div class="absolute" :class="(!editable || localStorage.getItem('multipleWarehouses') !== 'true' || orderData.status !== 'created') ? 'inactive-supplies-btn' : 'active-supplies-btn'">
          <span class="text-sm font-medium py-1 px-2 rounded tooltip" :class="editable ? 'text-primary' : 'text-darkgray'">{{$t('Additional Package')}}</span>
        </div>
        <div @click="addMorePackage" :class="(!editable || localStorage.getItem('multipleWarehouses') !== 'true' || orderData.status !== 'created') ? 'inactive-link bg-grey' : 'bg-primary cursor-pointer'" class="p-1 flex self-center rounded">
          <feather-icon icon="PlusIcon" svgClasses="w-5 h-5 text-white" />
        </div>
      </div>
      <div v-if="$route.params.type === 'delivery-orders'" class="mt-4">
        <p class="font-medium text-darkgray text-sm leading-tight">{{ $t('The parcel') }}</p>
        <div class="flex gap-1 items-center mt-1">
          <vs-switch style="transform: scale(0.9);" :disabled="!editable" v-model="orderData.is_customer_allowed_to_open_packages"/>
          <p :class="orderData.is_customer_allowed_to_open_packages ? 'text-primary' : 'text-grey'" class="font-semibold leading-tight">{{ $t('Allow open packages') }}</p>
        </div>
      </div>
    </div>
    <div v-if="$store.state.AppActiveUser.userRole === 'merchant'">
      <div class="flex gap-4 items-center justify-end" v-if="orderData.status === 'created'|| orderData.status === 'pickup_requested' || orderData.status === 'return_requested'">
        <vs-button class="mt-6 font-semibold text-sm" v-if="editable" @click="cancelPackageInfoEdit()" type="border" color="secondary">{{$t('Cancel')}}</vs-button>
        <vs-button class="mt-6 font-semibold text-sm" v-if="editable" @click="updatePackages()">{{$t('Save')}}</vs-button>
      </div>
    </div>
    <div class="flex gap-4 items-center justify-end" v-else-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'head-of-fleet' || $store.state.AppActiveUser.userRole === 'customer-support'">
      <vs-button class="mt-6 font-semibold text-sm" v-if="editable" @click="cancelPackageInfoEdit()" type="border" color="secondary">{{$t('Cancel')}}</vs-button>
      <vs-button class="mt-6 font-semibold text-sm" v-if="editable" @click="updatePackages()">{{$t('Save')}}</vs-button>
    </div>
  </shipblu-card>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import {sendRequest} from '@/http/axios/requestHelper.js'
import i18nData from '../../../i18n/i18nData'
import vSelect from 'vue-select'
import common from '../../../assets/utils/common'

export default {
  props: ['orderData', 'packageInfoCardLoading', 'merchantStatus'],
  data () {
    return {
      localStorage,
      editable: false,
      packagesCount: 0,
      packageSizes: [
        {
          description: 'Small Flyer (20x25cm)',
          id: 1,
          name: 'Small Flyer (20x25cm)',
          short_code: 'S-FLY'
        },
        {
          description: 'Medium Flyer (40x40cm)',
          id: 2,
          name: 'Medium Flyer (40x40cm)',
          short_code: 'M-FLY'
        },
        {
          description: 'Large Flyer (45x50cm)',
          id: 3,
          name: 'Large Flyer (45x50cm)',
          short_code: 'L-FLY'
        },
        {
          description: 'Extra Large Flyer (50x60cm)',
          id: 4,
          name: 'Extra Large Flyer (50x60cm)',
          short_code: 'XL-FLY'
        }
      ]
    }
  },
  watch: {
    'orderData' () {
      this.packagesCount = this.orderData.packages ? this.orderData.packages.length : 0
    }
  },
  methods: {
    editInformation () {
      this.editable = true
    },
    addMorePackage () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.orderData.packages.push({
            package: {
              package_size: '',
              description: '',
              fragile: false
            }
          })
        }
      })
    },
    updatePackages () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.packageInfoCardLoading = true
          const orderPackages = []
          this.orderData.packages.forEach(packageItem => {
            if (packageItem.package.id) {
              orderPackages.push({
                package_id: packageItem.package.id,
                package_size: packageItem.package.package_size.id,
                description: packageItem.package.description,
                fragile: packageItem.package.fragile
              })
            }
          })
          sendRequest(true, false, this, 'api/v1/packages/', 'patch', orderPackages, true,
            () => {
              if (this.orderData.packages && this.orderData.packages.length > this.packagesCount) {
                this.addPacakges()
              } else {
                this.updateOpenPackage()
              }
            },
            (error) => {
              common.notifyResponseError(this, error)
              this.$emit('loadOrder', 'packageInfoCardLoading')
            }
          )
        }
      })
    },
    addPacakges () {
      const packages = {
        id: this.orderData.id,
        packages: []
      }
      this.orderData.packages.forEach(packageItem => {
        if (!packageItem.package.id) {
          packages.packages.push({
            package_size: packageItem.package.package_size.id,
            description: packageItem.package.description,
            fragile: packageItem.package.fragile
          })
        }
      })
      sendRequest(true, false, this, 'api/v1/packages/', 'post', packages, true,
        () => {
          this.updateOpenPackage()
        },
        (error) => {
          common.notifyResponseError(this, error)
          this.$emit('loadOrder', 'packageInfoCardLoading')
        }
      )
    },
    updateOpenPackage () {
      let openPackageObj = {}
      openPackageObj = {
        is_customer_allowed_to_open_packages: this.orderData.is_customer_allowed_to_open_packages
      }
      sendRequest(true, false, this, `api/v1/${this.$route.params.type}/${this.$route.params.orderID}/`, 'patch', openPackageObj, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Packages'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.cancelPackageInfoEdit()
        },
        (error) => {
          common.notifyResponseError(this, error)
          this.$emit('loadOrder', 'packageInfoCardLoading')
        }
      )
    },
    cancelPackageInfoEdit () {
      this.$emit('loadOrder', 'packageInfoCardLoading')
      this.editable = !this.editable
    }
  },
  components: {
    ShipbluCard,
    vSelect
  }
}
</script>

<style lang="scss">
.active-supplies-btn {
  right: 33px;
  .tooltip {
    box-shadow: 0px 0px 6px rgba(108, 132, 163, 0.6);
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -7px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent #1C5BFE transparent transparent;
    }
  }
}
.inactive-supplies-btn {
  right: 33px;
  .tooltip {
    box-shadow: 0px 0px 6px rgba(108, 132, 163, 0.6);
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -7px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent #b8c2cc transparent transparent;
    }
  }
}
</style>